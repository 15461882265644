export function metaTitleAndDescription({
  title,
  description,
  content_type,
  image,
}: {
  title: string
  description: string
  content_type: string
  image?: string
}) {
  return [
    {
      title,
    },
    {
      name: 'og:title',
      content: title,
    },
    {
      name: 'description',
      content: description,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:type',
      content: content_type,
    },
    {
      name: 'og:site_name',
      content: 'Clube do Tennis',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    { name: 'og:image', content: image },
  ]
}
